import validator from "@/utils/validator";

export default {
    data: {
        validationErrors: {}
    },
    methods: {
        validateAndSubmit(data, rules, submitCallback) {
            this.clearValidationErrors();

            const validation = validator(rules).validateAll(data);

            if (!validation.hasErrors)
                return submitCallback();

            this.validationErrors = validation.validationErrors;
        },
        validateField(form, rules, field) {
            this.deleteFieldValidationError(field);

            this.validationErrors[field] = validator(rules).validate(field, form[field]);
        },
        getFieldValidationError(field) {
            return this.validationErrors[field] || null;
        },
        deleteFieldValidationError(field) {
            this.validationErrors[field] = null;
        },
        clearValidationErrors() {
            this.validationErrors = {};
        }
    }
}
